// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/constant/images.ts"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import carouselAboutWattshift from "~/assets/images/carouselAboutWattshift.png";
import carouselSaveMoney from "~/assets/images/carouselSaveMoney.png";
import carouselConnectData from "~/assets/images/carouselConnectData.png";
import logo from "~/assets/images/logo.png";
import homeIcon from "~/assets/images/homeIcon.png";
import rightIcon from "~/assets/images/rightIcon.png";
import leapConnect from "~/assets/images/leapConnect.png";
import thermostatConnect from "~/assets/images/thermostatConnect.png";

import note from "~/assets/images/note.png";
import thermostat from "~/assets/images/thermostat.png";
import electricity from "~/assets/images/electricity.png";
import downIcon from "~/assets/images/downIcon.png";
import upArrow from "~/assets/images/upArrow.png";
import downArrow from "~/assets/images/downArrow.png";
import moonIcon from "~/assets/images/moonIcon.png";
import sunIcon from "~/assets/images/sunWhiteIcon.png";

import calenderIcon from "~/assets/images/calenderIcon.png";
import sunHeader from "~/assets/images/sunHeader.png";
import nightHeader from "~/assets/images/nightHeader.png";
import done from "~/assets/images/doneheader.png";
import calculating from "~/assets/images/calculating.png";
import nightMoon from "~/assets/images/nightMoon.png";
import sunDay from "~/assets/images/sunDay.png";

import menu from "~/assets/images/menu.png";
import feedback from "~/assets/images/feedback.png";

export const images = {
  carouselAboutWattshift,
  carouselConnectData,
  carouselSaveMoney,
  logo,
  homeIcon,
  rightIcon,
  leapConnect,
  thermostatConnect,
  note,
  thermostat,
  electricity,
  downIcon,
  upArrow,
  downArrow,
  moonIcon,
  sunIcon,
  calenderIcon,
  sunHeader,
  nightHeader,
  done,
  calculating,
  nightMoon,
  sunDay,
  menu,
  feedback,
};
